<script setup lang="ts">
import type { UIZoomableImageProps } from './UIZoomableImage.props'
import { useDrag, usePinch } from '@vueuse/gesture'

const props = defineProps<UIZoomableImageProps>()

const ZOOM_LEVEL = 2.5
const DRAG_SENSITIVITY = 2
const PINCH_SCALE_THRESHOLD = 0.1
const DOUBLE_TAP_DELAY = 300

const imageStyle = ref({})
const imageRef = ref<HTMLElement | undefined>()
const lastPointerType = ref('mouse')
const lastTap = ref(0)
const tapTimeout = ref<ReturnType<typeof setTimeout> | null>(null)

const emit = defineEmits<{
  toggleZoom: [isZoomEnabled: boolean]
  isPinching: [isPinching: boolean]
}>()

const zoomState = reactive({
  isZoomEnabled: false,
  originX: 50,
  originY: 50,
  initialOriginX: 50,
  initialOriginY: 50,
})

const gestureState = reactive({
  isPinching: false,
  pinchDetected: false,
  pinchInitialDistance: 0,
  isDragging: false,
})

const setZoomStatus = (status: boolean, originX: number, originY: number) => {
  zoomState.isZoomEnabled = status
  zoomState.originX = originX
  zoomState.originY = originY
  emit('toggleZoom', status)
}

const resetZoom = (originX = 50, originY = 50) => {
  setZoomStatus(false, originX, originY)
  gestureState.pinchInitialDistance = 0
  setPinchStatus(false)
  updateImageStyle(1, true)
}

const setPinchStatus = (status: boolean) => {
  gestureState.isPinching = status
  emit('isPinching', status)
}

function updateImageStyle(scale = ZOOM_LEVEL, transition = false) {
  imageStyle.value = {
    transform: `scale(${scale}) translate3d(0px, 0px, 0)`,
    transformOrigin: `${zoomState.originX}% ${zoomState.originY}%`,
    transition: transition ? 'transform 0.3s ease' : '',
  }
}

// Drag handler (mobile move)
useDrag(
  state => {
    const {
      movement: [mx, my],
      dragging,
      event,
      touches,
      first,
      last,
    } = state

    if (!zoomState.isZoomEnabled || gestureState.isPinching) {
      return
    }

    if (event instanceof TouchEvent && touches === 1) {
      if (dragging) {
        if (first) {
          zoomState.initialOriginX = zoomState.originX
          zoomState.initialOriginY = zoomState.originY
        }
        gestureState.isDragging = true

        const target = imageRef.value
        if (target) {
          const imageData = target.getBoundingClientRect()

          const deltaOriginX =
            (((mx / imageData.width) * 100) / ZOOM_LEVEL) * DRAG_SENSITIVITY
          const deltaOriginY =
            (((my / imageData.height) * 100) / ZOOM_LEVEL) * DRAG_SENSITIVITY
          const deltaX = zoomState.initialOriginX - deltaOriginX
          const deltaY = zoomState.initialOriginY - deltaOriginY

          zoomState.originX = Math.max(0, Math.min(100, deltaX))
          zoomState.originY = Math.max(0, Math.min(100, deltaY))

          updateImageStyle()
        }
      }

      if (last) {
        gestureState.isDragging = false
      }
    }
  },
  {
    domTarget: imageRef,
    filterTaps: true,
    delay: true,
    eventOptions: { capture: true, passive: false },
  }
)

// Pinch handler (Zoom)
usePinch(
  state => {
    const { da, origin, first, last } = state
    const target = imageRef.value
    if (!target) {
      return
    }

    state.event.preventDefault()

    const imageData = target.getBoundingClientRect()

    const x = ((origin[0] - imageData.left) / imageData.width) * 100
    const y = ((origin[1] - imageData.top) / imageData.height) * 100

    if (first) {
      gestureState.pinchInitialDistance = da[0]
      gestureState.pinchDetected = false
      setPinchStatus(true)
    }

    if (da.length < 2) {
      return
    }

    const currentDistance = da[0]
    const scaleChange = currentDistance / gestureState.pinchInitialDistance

    if (
      Math.abs(scaleChange - 1) > PINCH_SCALE_THRESHOLD &&
      !gestureState.pinchDetected
    ) {
      gestureState.pinchDetected = true

      if (scaleChange > 1 + PINCH_SCALE_THRESHOLD && !zoomState.isZoomEnabled) {
        setZoomStatus(true, x, y)
        updateImageStyle(ZOOM_LEVEL, true)
      } else if (
        scaleChange < 1 - PINCH_SCALE_THRESHOLD &&
        zoomState.isZoomEnabled
      ) {
        resetZoom(zoomState.originX, zoomState.originY)
        setPinchStatus(true)
      }
    }

    if (last) {
      gestureState.pinchDetected = false
      // transition timeout 'transform 0.3s ease'
      setTimeout(() => {
        setPinchStatus(false)
      }, 300)
    }
  },
  {
    domTarget: imageRef,
    eventOptions: { capture: true, passive: false },
  }
)

const handlePointerMove = (event: PointerEvent) => {
  if (event.pointerType !== 'mouse') {
    return
  }
  const target = event.target as HTMLElement
  if (target.tagName !== 'IMG') return

  const x = (event.offsetX / target.clientWidth) * 100
  const y = (event.offsetY / target.clientHeight) * 100

  if (!zoomState.isZoomEnabled) {
    return
  }
  zoomState.originX = x
  zoomState.originY = y

  updateImageStyle()
}

useEventListener(imageRef, 'pointerdown', (event: PointerEvent) => {
  lastPointerType.value = event.pointerType
})

const handleClick = (event?: MouseEvent) => {
  if (gestureState.pinchDetected) {
    return
  }
  if (event) {
    const target = event.target as HTMLElement
    if (target.tagName !== 'IMG') {
      return
    }

    const x = (event.offsetX / target.clientWidth) * 100
    const y = (event.offsetY / target.clientHeight) * 100

    const isTouchDevice = lastPointerType.value === 'touch'

    if (isTouchDevice) {
      const currentTime = Date.now()
      const tapDelta = currentTime - lastTap.value
      if (tapDelta > 0 && tapDelta < DOUBLE_TAP_DELAY) {
        // valid double tap
        if (!zoomState.isZoomEnabled) {
          setZoomStatus(true, x, y)
          updateImageStyle(ZOOM_LEVEL, true)
        } else {
          resetZoom(zoomState.originX, zoomState.originY)
        }
        lastTap.value = 0
        if (tapTimeout.value) {
          clearTimeout(tapTimeout.value)
          tapTimeout.value = null
        }
      } else {
        // first tap
        lastTap.value = currentTime
        if (tapTimeout.value) {
          clearTimeout(tapTimeout.value)
        }
        tapTimeout.value = setTimeout(() => {
          lastTap.value = 0
          tapTimeout.value = null
        }, DOUBLE_TAP_DELAY)
      }
    } else {
      // Mouse click
      if (!zoomState.isZoomEnabled) {
        setZoomStatus(true, x, y)
        updateImageStyle(ZOOM_LEVEL, true)
      } else {
        resetZoom(zoomState.originX, zoomState.originY)
      }
    }
  }
}

const sizes = computed(() => {
  return props.fullSize ? '' : 'sm:100vw md:100vw lg:50vw'
})

watch(
  () => props.slideIndex,
  () => {
    resetZoom()
  }
)

onUnmounted(() => {
  if (tapTimeout.value) {
    clearTimeout(tapTimeout.value)
    tapTimeout.value = null
  }
})
</script>

<template>
  <div
    class="zoomable-image-wrapper relative flex w-screen items-center justify-center overflow-hidden md:h-dvh"
  >
    <div ref="imageRef" class="inline-block">
      <NuxtImg
        :src="src"
        :alt="alt"
        :style="imageStyle"
        tabindex="0"
        provider="cloudinary"
        quality="100"
        class="z-[9000] h-dvh w-full object-contain will-change-transform"
        :class="{
          'custom-zoom-cursor-add': !zoomState.isZoomEnabled,
          'custom-zoom-cursor-remove': zoomState.isZoomEnabled,
          'aspect-pdp-image': !title && !aspectRatio,
          [`aspect-${aspectRatio}`]: aspectRatio,
          [title ? 'h-[90dvh]' : 'h-dvh']: true,
          'h-[85dvh]': isGallerySlideShow,
        }"
        :sizes="sizes"
        loading="lazy"
        @click="handleClick"
        @keydown.enter="handleClick"
        @pointermove="handlePointerMove"
      />
      <span
        v-if="title"
        class="flex h-[10dvh] items-center justify-end lg:px-0"
      >
        {{ title }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-zoom-cursor-add {
  cursor:
    url('@design-system/icons/AddWBkg.svg') 24 24,
    auto;
}
.custom-zoom-cursor-remove {
  cursor:
    url('@design-system/icons/RemoveWBkg.svg') 24 24,
    auto;
}
</style>
